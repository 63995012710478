/* eslint-disable jsx-a11y/alt-text */
import { Button } from "@/components/ui/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "@/contexts/appContext";
import { useTestContext } from "@/contexts/testContext";
import { DateTime } from "luxon";
import TakePhotoButton from "./TakePhotoButton";
import useTimer from "@/hooks/useTimer";
import { TimerStatus } from "@/hooks/useTimer";
import { Drawer, DrawerContent, DrawerTitle } from "@/components/ui/drawer";
import PhotoCorrectionAnimation from "@/assets/mp4/Photo_Correction_Animation.mp4";
import StripMissingAnimation from "@/assets/mp4/Strip_Missing_Animation.mp4";
import AnalysisScreen from "@/components/AnalysisScreen/AnalysisScreen";
import AnalysisCompleteModal from "@/components/AnalysisScreen/AnalysisCompleteModal";
import BlurryPhotoAnimation from "@/assets/mp4/Blurry_Photo_Animation.mp4";
import { AnalysisScreenContextProvider } from "@/contexts/analysisScreenContext";
import TakePhotoTimer from "./TakePhotoTimer";
import SimpleAnimation from "@/components/AnalysisScreen/SimpleAnimation";
import { logService } from "@/services/logService";

const ENABLE_ADVANCED_ANIMATION =
  parseInt(process.env.REACT_APP_ENABLE_ADVANCED_ANIMATION || "") === 1;

function AnalyzeStrip() {
  const { session, isLoggedIn } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const stripPhoto = location.state?.stripPhoto;
  const takePhotoTimeLeftInSeconds =
    location.state?.takePhotoTimer / 1000 || undefined;
  const takePhotoTimer = useTimer({ time: takePhotoTimeLeftInSeconds });
  const {
    testResultData,
    stripPhoto: contextStripPhoto,
    error,
    analyzeStripPhoto,
    resetContext,
  } = useTestContext();
  const [errorState, setErrorState] = useState<any>(undefined);
  const [isAnalysisComplete, setIsAnalysisComplete] = useState(false);
  const [disableTakePhoto, setDisableTakePhoto] = useState(false);
  const [hasAdvancedAnimationError, setHasAdvancedAnimationError] = useState(false);

  const goToResult = useCallback(() => {
    if (testResultData) {
      resetContext();
      navigate("/test/result", {
        state: {
          resultData: testResultData,
          stripPhoto: contextStripPhoto || stripPhoto,
          resultDate: DateTime.local().toISO(),
        },
        replace: true,
      });
    }
  }, [contextStripPhoto, navigate, stripPhoto, testResultData, resetContext]);

  const handleRetakePhoto = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (isLoggedIn && session?.user?.id) {
          analyzeStripPhoto(file, session.user.id);
        } else {
          analyzeStripPhoto(file);
        }
      }
    },
    [analyzeStripPhoto, isLoggedIn, session]
  );

  const errorProps = useMemo(() => {
    if (!errorState) return undefined;

    switch (true) {
      case errorState.response?.data?.error === "BlurredImageError":
        return {
          animation: BlurryPhotoAnimation,
          title: "The photo is blurry",
          description: "Make sure the Color Card and test strip are in-focus.",
        };
      case errorState.response?.data?.error === "StripNotFoundError":
        return {
          animation: StripMissingAnimation,
          title: "The test strip is missing",
          description:
            "Make sure the test strip is placed in the black area of the Color Card.",
        };
      default:
        return {
          animation: PhotoCorrectionAnimation,
          title: "Sorry, we’re unable to analyze your photo",
          description:
            "Make sure the entire Color Card is visible, in-focus and in good lighting.",
        };
    }
  }, [errorState]);

  useEffect(() => {
    if (typeof takePhotoTimer.time === "number") {
      takePhotoTimer.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takePhotoTimeLeftInSeconds]);

  useEffect(() => {
    if (takePhotoTimer.status === TimerStatus.Expired) {
      setDisableTakePhoto(true);
    } else {
      setDisableTakePhoto(false);
    }
  }, [takePhotoTimer.status]);

  const handleDrawerAnimationEnd = useCallback(() => {
    if (!error) {
      setErrorState(undefined);
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      if (!contextStripPhoto) {
        navigate("/test");
      }
    }, 200);
  }, [contextStripPhoto, navigate]);

  useEffect(() => {
    if (error) {
      setErrorState(error);
    }
  }, [error]);

  const AnimationComponent = (
    !hasAdvancedAnimationError &&
    ENABLE_ADVANCED_ANIMATION
  ) ? (
    (() => {
      logService.captureMessage("Advanced animation running", "info")
      return (
        <AnalysisScreenContextProvider>
          <AnalysisScreen
            stripPhoto={contextStripPhoto || stripPhoto}
            testResultData={testResultData}
            onError={() => setHasAdvancedAnimationError(true)}
            onAnalysisComplete={() => {
              setTimeout(() => {
                setIsAnalysisComplete(true);
              }, 2200);
            }}
          />
        </AnalysisScreenContextProvider>
      );
    })()
  ) : (
    (() => {
      logService.captureMessage("Simple animation running", "info");
      return (
        <SimpleAnimation
          onAnimationIdle={goToResult}
          testResultData={testResultData}
          stripPhoto={contextStripPhoto || stripPhoto}
        />
      );
    })()
  );

  return (
    <div className="w-full max-w-screen-sm mx-auto px-5 py-8">
      {AnimationComponent}
      <Drawer
        open={disableTakePhoto && !!error}
        onOpenChange={setDisableTakePhoto}
      >
        <DrawerContent>
          <div className="w-full max-w-screen-sm mx-auto px-5 py-5">
            <TakePhotoTimer time={takePhotoTimer.time} className="mb-6" />
            <DrawerTitle asChild className="text-2xl">
              <h2 className="text-center font-semibold mb-3">
                "Uh oh! You ran out of time to take a photo of your test"
              </h2>
            </DrawerTitle>
            <div className="text-center mb-6">
              <p>
                In order to provide an accurate result, the test strip can only
                be interpreted within 2 minutes after it has developed.
              </p>
              <p>Please try testing again with a new test kit.</p>
            </div>
            <div className="flex flex-col items-stretch space-y-4">
              <Button asChild>
                <Link to="/">Go Home</Link>
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
      <Drawer open={!!error} modal={true} dismissible={false}>
        <DrawerContent onAnimationEnd={handleDrawerAnimationEnd}>
          {errorProps && (
            <div className="w-full max-w-[380px] mx-auto px-5 py-5">
              <TakePhotoTimer time={takePhotoTimer.time} className="mb-6" />
              <div className="mb-7">
                <video
                  className="w-full h-100 rounded-lg"
                  playsInline
                  muted
                  autoPlay
                  preload="auto"
                  loop
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  <source src={errorProps.animation} type="video/mp4" />
                </video>
              </div>
              <DrawerTitle asChild className="text-2xl">
                <h2 className="text-center font-semibold mb-5">
                  {errorProps.title}
                </h2>
              </DrawerTitle>
              <p className="mb-6 text-center">{errorProps.description}</p>
              <TakePhotoButton
                variant="outline"
                onChangePicture={handleRetakePhoto}
                className="w-full"
              >
                Retake Photo
              </TakePhotoButton>
            </div>
          )}
        </DrawerContent>
      </Drawer>
      <AnalysisCompleteModal
        open={isAnalysisComplete}
        onOpenChange={() => {}}
        onViewResults={goToResult}
      />
    </div>
  );
}

export default AnalyzeStrip;
