import {
  chatResponseLoadingAtom,
  conversationIdAtom,
  messagesAtom,
} from "@/store/atoms/messageAtom";
import axios from "@/services/axios";
import { useAtom } from "jotai";
import { v4 as uuidv4 } from "uuid";
import {
  NUMERIC_MEASUREMENT,
  NUMERIC_MEASUREMENT_UNITS,
} from "@/utils/variables";
import { useEffect, useRef } from "react";

const useChat = (userId: string | null, data: any, accessToken: string) => {
  const [messages, setMessage] = useAtom(messagesAtom);
  const [_chatResponseLoading, setChatResponseLoading] = useAtom(
    chatResponseLoadingAtom
  );
  const [conversationId] = useAtom(conversationIdAtom);
  const controllerRef = useRef<AbortController | null>(null);

  const addSystemMessage = (currentMessages: any, messageContent: string) => {
    const systemMessage = {
      id: uuidv4(),
      message: messageContent,
      isUser: false,
    };
    setMessage([...currentMessages, systemMessage]);
  };

  const onSend = async (text: string) => {
    try {
      setChatResponseLoading(true);
      controllerRef.current = new AbortController();
      const signal = controllerRef.current.signal;
      const newMessage = { id: uuidv4(), message: text, isUser: true };
      const updatedMessages = [...messages, newMessage];
      setMessage(updatedMessages);

      if (!data?.predictions) {
        addSystemMessage(
          updatedMessages,
          "You don't have the latest test data."
        );
        return;
      }

      if (text === "Can I ask you a question?") {
        addSystemMessage(
          updatedMessages,
          "Sure! Please feel free to ask your question about your test results — I'm here to help!"
        );
        return;
      }
      const predictions = data.predictions;

      const result: any = {};

      for (const parameter in predictions) {
        if (Object.prototype.hasOwnProperty.call(predictions, parameter)) {
          const predictionIndex = predictions[parameter];
          const value = NUMERIC_MEASUREMENT[parameter]?.[predictionIndex];
          const unit = NUMERIC_MEASUREMENT_UNITS[parameter];

          result[parameter] = `${value} ${unit}`;
        }
      }

      const payload = {
        user_id: userId,
        test_result: JSON.stringify(result),
        query: text,
        conversation_id: conversationId,
      };

      const response = await axios.post(
        process.env.REACT_APP_CHATBOT_API!,
        payload,
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          },
          signal,
        }
      );
      addSystemMessage(updatedMessages, response.data);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setChatResponseLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      controllerRef.current?.abort();
    };
  }, []);

  return { onSend, messages };
};

export default useChat;
