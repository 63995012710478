import { cn } from "@/lib/utils";
import { Alert, AlertDescription } from "./ui/alert";
import { default as AlertIcon } from "@/components/icons/Alert";
import { Button } from "./ui/button";
import { ArrowRight } from "lucide-react";

interface IProps {
  className?: string;
  onClickTalkToDoctor: () => void;
}

export default function UTIAlert({ className, onClickTalkToDoctor }: IProps) {
  return (
    <Alert variant="warning" className={cn("[&>svg]:top-4", className)}>
      <AlertIcon />
      <AlertDescription>
        <p className="text-3xl" style={{ marginTop: "-10px" }}>UTI Detected</p>
        <p className="mb-3">
          Results indicate you may have a UTI. Treatment may be available via
          telehealth.
        </p>
        <Button
          variant="outline"
          className="w-full space-x-3"
          onClick={onClickTalkToDoctor}
        >
          <span className="text-xl">Get Prescription</span>
          <ArrowRight />
        </Button>
      </AlertDescription>
    </Alert>
  );
}
