import posthog from "posthog-js";
import axios from "axios";
import * as Sentry from "@sentry/react";
import AppLogo2 from "@/components/AppLogo/AppLogo2";
import Cart from "@/components/icons/Cart";
import { Button } from "@/components/ui/button";
import { ORDER_TEST_KITS_URL, QUERY_KEY } from "@/utils/variables";
import BgImage from "@/assets/img/home7.png";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { Session } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { supabaseService } from "@/services/supabaseService";
import { useMemo, useEffect } from "react";
import { DateTime } from "luxon";
import { checkHasAbnormalResults } from "@/utils/scanUtils";
import ResultsHistoryCard from "@/components/ResultsHistoryCard";
import OverrideTheme from "@/components/OverrideTheme";
import Spinner from "@/components/Spinner";
import Chatbot from "@/components/Chatbot/Chatbot";
import SlidersIcon from "@/components/icons/SlidersIcon";
import SubscriptionAlert from "@/components/SubscriptionAlert";
import { capitalizeFirstLetter } from "@/utils/common";


interface IProps {
  session: Session;
}

export default function AuthenticatedHomePage({ session }: IProps) {
  const supabase = supabaseService.getClient();
  const user = session.user;
  const CHECK_SUBSCRIPTION_URL = process.env.REACT_APP_CHECK_SUBSCRIPTION_API || "";
  const ENFORCE_SUBSCRIPTION = parseInt(process.env.REACT_APP_ENFORCE_SUBSCRIPTION || "") === 1;

  useEffect(() => {
    posthog.identify(
      session.user.id,
      {
        email: session.user.user_metadata.email,
        phone: session.user.phone
      }
    );
    Sentry.setUser({
      id: session.user.id,
      email: session.user.user_metadata.email,
      username: session.user.phone
    });

  }, []);

  const shouldFetchSubscriptionData = () => {
    const retrievedStatus = sessionStorage.getItem("subscriptionStatus");
    const parsedStatus =
      retrievedStatus && retrievedStatus !== "null" ?
      JSON.parse(retrievedStatus) : null;

    const retrievedExpiration = sessionStorage.getItem("subscriptionExpiration");
    const parsedExpiration =
      retrievedExpiration && retrievedExpiration !== "null" ?
      new Date(retrievedExpiration) : null;

    return !parsedStatus || !parsedExpiration || new Date(parsedExpiration) < new Date();
  };

  const { data: subscriptionData } = useQuery({
    queryKey: ["subscriptionResults"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("Profiles")
        .select("subscriptionStatus, subscriptionExpiration")
        .eq("id", session.user.id);

      if (error) {
        throw error;
      }
      return data[0];
    },
    enabled: !!session?.user.id && shouldFetchSubscriptionData(),
  });

  useEffect(() => {
    if (!subscriptionData) return;

    const { subscriptionStatus, subscriptionExpiration } = subscriptionData;

    sessionStorage.setItem("subscriptionStatus", JSON.stringify(subscriptionStatus));
    sessionStorage.setItem("subscriptionExpiration", subscriptionExpiration);

    if (CHECK_SUBSCRIPTION_URL && (!subscriptionStatus! || !subscriptionExpiration)) {
      axios.post(
        CHECK_SUBSCRIPTION_URL,
        { phone_number: session?.user.phone },
        {
          headers: {
            "Authorization": `Bearer ${session.access_token}`
          }
        }
      ).catch(error => {
        console.error(error.response);
      });
    }
  }, [subscriptionData]);

  const isSubscriptionExpired =
    subscriptionData &&
    subscriptionData.subscriptionExpiration &&
    new Date(subscriptionData.subscriptionExpiration) < new Date();

  const showAlert =
    subscriptionData &&
    (!subscriptionData.subscriptionStatus ||
    isSubscriptionExpired);

  const resultsQuery = useQuery({
    queryKey: [QUERY_KEY.scanResults],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("UserScan")
        .select("*, Scan (*, Result (*))")
        .eq("user_uid", session.user.id)
        .limit(10)
        .order("created_at", { ascending: false });

      if (error) {
        throw error;
      } else {
        return data;
      }
    },
    enabled: !!session?.user.id,
  });

  const getGreeting = () => {
    let greeting = ''
    if (session.user.user_metadata.first_name){
      greeting = "Hi, " + capitalizeFirstLetter(session.user.user_metadata.first_name)+ "."
    } else {
      greeting = "Welcome."
    }
    return greeting;
  }

  const recentTests = useMemo(() => {
    if (resultsQuery.isSuccess) {
      return resultsQuery.data
        .filter((result) => !!(result["Scan"] && result["Scan"]["Result"][0]))
        .map((result) => {
          const locale = "en";
          const scanDate = DateTime.fromISO(
            result["Scan"]["Result"][0].created_at
          ).setLocale(locale);
          const localDate = DateTime.local().setLocale(locale);
          const diff = localDate.diff(scanDate, ["days"]);
          const diffInDays = Math.floor(diff.days);
          let dateText = scanDate.toFormat("DDD t");

          switch (true) {
            case diffInDays === 0:
              dateText = `Today at ${scanDate.toFormat("t")}`;
              break;
            case diffInDays <= 30:
              dateText = `${diffInDays} day${diffInDays === 1 ? "" : "s"} ago`;
              break;
            default:
              break;
          }

          const hasAbnormalResults = checkHasAbnormalResults(
            result["Scan"]["Result"][0].color_data.predictions
          );
          const data = {
            dateText,
            resultData: result,
            hasAbnormalResults,
          };
          return data;
        });
    }

    return [];
  }, [resultsQuery]);
  return (
    <>
      <div className="w-full max-w-screen-sm mx-auto px-5 pb-5">
          {showAlert && ENFORCE_SUBSCRIPTION && (
            <SubscriptionAlert isExpired={isSubscriptionExpired} session={session} />
          )}
        <div className="flex items-center justify-between py-7">
          <AppLogo2 width={106} height={24} />
          {user && (
            <Link
              to="/profile"
              className="border-2 border-ribbon-cobalt-700 rounded-full p-2"
            >
              <SlidersIcon className="text-ribbon-cobalt-700" />
            </Link>
          )}
        </div>
        <div
          className="rounded-lg overflow-hidden mb-6 relative"
        >
          {/* Use an img tag for exact control over the image */}
          <img 
            src={BgImage}
            alt="Man checking health device" 
            className="w-full object-cover"
          />
          
          {/* Overlay content positioned absolutely */}
          <div className="absolute inset-0 flex flex-col items-center p-5">
            <div className="flex-1 flex items-center justify-center">
              <h2 className="text-2xl text-white font-bold text-center">
                {getGreeting()}
              </h2>
            </div>
            
            <div className="w-full">
              <Button
                variant="secondary"
                className="font-medium bg-[#EF5400] hover:bg-[#EF5400] text-white text-lg w-full rounded-full py-3 flex items-center justify-center mt-auto"
                asChild
              >
                <Link to="/tutorial">
                  <span>Start your checkup</span>
                  <ArrowRight className="ml-2 h-7 w-7" />
                </Link>
              </Button>
              <Button
              variant="secondary"
              className="font-medium bg-[#013EE6] hover:bg-[#0032B8] text-white text-lg w-full rounded-full py-3 flex items-center justify-center mt-auto"
              style={{marginTop: '10px'}}
              asChild
            >
              <Link to="/test-history">
                <span>View Past Results</span>
              </Link>
            </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-2">
            <p className="text-ribbon-stone-900 text-lg text-center w-full">Recent Tests</p>
          </div>
          {resultsQuery.isLoading ? (
            <>
              <Spinner />
            </>
          ) : (
            <>
              {recentTests.length === 0 ? (
                <div className="p-8 flex flex-col items-center justify-center bg-ribbon-stone-300 rounded-md">
                  <h3 className="font-bold text-center mb-4">No tests yet!</h3>
                  <p className="text-center">
                    Recent results will appear here once you have completed a
                    test.
                  </p>
                </div>
              ) : (
                <div className="space-y-4">
                  {recentTests.map((item) => (
                    <ResultsHistoryCard
                      key={item.resultData.id}
                      id={item.resultData.id}
                      hasAbnormalResults={item.hasAbnormalResults}
                      dateText={item.dateText}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        <OverrideTheme background="182 14% 96%" />
      </div>
      {ENFORCE_SUBSCRIPTION && !showAlert && resultsQuery?.data && resultsQuery.data.length > 0 && (
        <Chatbot
          data={resultsQuery.data[0]?.Scan?.Result?.[0]?.color_data}
          userId={resultsQuery.data[0]?.user_uid}
          sessionToken={session.access_token}
        />
      )}
    </>
  );
}
