import axios from "axios";
import { Session } from "@supabase/supabase-js";
import { useState } from 'react';
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import {
  Drawer,
  DrawerContent,
  DrawerTitle,
  DrawerFooter
} from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ExternalLink } from "lucide-react";
import { ORDER_TEST_KITS_URL } from "@/utils/variables";

interface IProps {
  isExpired: boolean;
  session: Session;
}

export default function SubscriptionAlert({ isExpired, session }: IProps) {
  const CHECK_SUBSCRIPTION_URL = process.env.REACT_APP_CHECK_SUBSCRIPTION_API || "";

  const [isOpen, setIsOpen] = useState(false);
  const [boughtSubscriptiong, setBoughtSubscription] = useState<boolean | null>(null);
  const [subscriptionSearch, setSubscriptionSearch] = useState("");
  const [hasSubscription, setHasSubscription] = useState<boolean | null>(null);

  const handleOpenDrawer = () => {
    setIsOpen(true);
  };

  const handleNoSubscription = () => {
    setBoughtSubscription(false);
  };

  const handleHasSubscription = () => {
    setBoughtSubscription(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
    setBoughtSubscription(null);
    setSubscriptionSearch("");
    setHasSubscription(null);
  };

  const handleSearchSubscription = () => {
    axios.post(
      CHECK_SUBSCRIPTION_URL,
      {
        phone_number: session?.user.phone,
        email: subscriptionSearch
      },
      {
        headers: {
          "Authorization": `Bearer ${session.access_token}`
        }
      }
    ).then(response => {
      const updated_data = response.data?.updated_data[0]
      if (
        response.status === 200 && updated_data &&
        updated_data.id === session.user.id
      ) {
        setHasSubscription(updated_data.subscriptionStatus)
      } else {
        setHasSubscription(false)
      }
    }).catch(error => {
      console.error(error.response);
      setHasSubscription(false)
    });
  };

  return (
    <>
      <Alert variant="warning" className="w-full mt-4">
        <AlertTitle>No Active Subscription Detected</AlertTitle>
        <AlertDescription>
          {isExpired
            ? "Your subscription has expired. "
            : "We're unable to find a subscription under your account. "
          }
          {isExpired ? (
            <a href={ORDER_TEST_KITS_URL}
              target="_blank"
              rel="noreferrer"
              className="underline text-ribbon-cobalt-700 font-medium"
            >
              Renew your subscription here
            </a>
          ) : (
            <>
              {" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenDrawer();
                }}
                className="underline text-ribbon-cobalt-700 font-medium"
              >
                Verify your subscription
              </a>
            </>
          )}
        </AlertDescription>
      </Alert>

      <Drawer open={isOpen} onOpenChange={setIsOpen} onClose={handleDrawerClose}>
        <DrawerContent className="w-full max-w-screen-sm mx-auto px-5 py-5">
          <DrawerTitle className="text-center font-semibold mb-3 flex flex-row items-center gap-4 justify-center">Subscription Verification</DrawerTitle>
          <div className="p-4">
            {boughtSubscriptiong === null && (
              <div className="py-4">
                <h3 className="text-lg font-medium mb-4">Have you recently bought a subscription?</h3>
                <div className="flex gap-3">
                  <Button onClick={handleHasSubscription} className="flex-1">Yes</Button>
                  <Button onClick={handleNoSubscription} variant="outline" className="flex-1">No</Button>
                </div>
              </div>
            )}
            {boughtSubscriptiong === true && hasSubscription === null && (
              <div className="py-4">
                <h3 className="text-lg font-medium mb-4">Please enter the email you bought the subscription with</h3>
                <div className="flex gap-2">
                  <Input
                    value={subscriptionSearch}
                    onChange={(e) => setSubscriptionSearch(e.target.value)}
                    placeholder="Enter email"
                    className="flex-1"
                  />
                  <Button onClick={handleSearchSubscription}>Search</Button>
                </div>
              </div>
            )}
            {hasSubscription !== null && (
              hasSubscription ? (
                <p className="text-lg">You have an active subscription. Please reload page to use the app</p>
              ) : (
                <p className="text-lg">
                  We are unable to find a subscription with that email. Please contact us at
                  <a href="mailto:support@scanbase.com" className="text-blue-500 hover:underline"> support@scanbase.com</a>.
                </p>
              )
            )}
            {boughtSubscriptiong === false && (
              <div className="py-4">
                <p className="mb-4">You need an active subscription to use all features of the app.</p>
                <Button asChild className="w-full">
                  <a href={ORDER_TEST_KITS_URL} target="_blank" rel="noreferrer">
                    Purchase Subscription <ExternalLink className="ml-2 h-4 w-4" />
                  </a>
                </Button>
              </div>
            )}
          </div>
          <DrawerFooter>
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Close Verification
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
