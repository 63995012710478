import { animated, useSpring, useSprings } from "@react-spring/web";
import { useCallback, useEffect, useRef, useState } from "react";
import Spinner from "../Spinner";
import useMeasure from "react-use-measure";
import { pt, vectorDistance } from "@/utils/common";
import { ImageCardDetector } from "@scanbase/fe-algo-module";
import { gsap } from "gsap";
import { IScanResult } from "@/types/ScanResult";
import { useAnalysisScreenContext } from "@/contexts/analysisScreenContext";
import ResultsScene from "@/components/AnalysisScreen/ResultsScene";
import { AnalysisScreenProgressText } from "@/types/common";
import AnimationCanvas from "@/components/AnalysisScreen/AnimationCanvas";

let initTimerId: any = null;
let delayTimerId: any = null;

interface IProps {
  stripPhoto?: File;
  testResultData?: IScanResult;
  onAnalysisComplete?: () => void;
  onError?: () => void;
}

export default function AnalysisScreen({
  stripPhoto,
  testResultData,
  onError,
  onAnalysisComplete = () => {},
} : IProps) {
  const {
    imageCanvasRef,
    animationCanvasRef,
    imageCardDetectorRef,
    config: {
      overlayAnimationDuration,
      dotSize,
      dotsAnimationDuration,
      nDots,
      initialScenePaddingY,
    },
    showDots,
    setShowDots,
    showPhotoOverlay,
    setShowPhotoOverlay,
    setShowResults,
    scanResultData,
    setScanResultData,
    setStripCorners,
    stripCorners,
    stripAnchorUseMeasure,
    setAnalysisScreenProgressText,
    progressTextAnimation,
    stripViewportPositionTrackingPointRefs,
    lColorBlockGsapContextRef,
    lColorBlockGsapRef,
    rColorBlockGsapContextRef,
    rColorBlockGsapRef,
    roiGsapContextRef,
    roiGsapRef,
  } = useAnalysisScreenContext();
  const stripAnchorMeasure = stripAnchorUseMeasure[1];
  const [stripPhotoSize, setStripPhotoSize] = useState({
    width: 0,
    height: 0,
  });
  const [photoSceneScale, setPhotoSceneScale] = useState(1);
  const [animatinInittTimestamp, setAnimationInitTimestamp] = useState(
    Date.now()
  );
  const [initialSceneRef, initialSceneMeasure] = useMeasure();
  const [
    initialSceneAnalysisScreenProgressTextContainerRef,
    initialSceneAnalysisScreenProgressTextContainerMeasure,
  ] = useMeasure();
  const [initialScenePhotoContainerRef, initialScenePhotoContainerMeasure] =
    useMeasure();
  const [perspxTransformMatrix, setPerspxTransformMatrix] = useState<
    string | undefined
  >(undefined);
  const perspxTransformWrapRef = useRef<HTMLDivElement>(null);

  const [photoSceneAnimation] = useSpring(() => {
    const sceneContainerMeasure = initialScenePhotoContainerMeasure;
    const maxWidth = sceneContainerMeasure.width;
    const maxHeight =
      initialSceneMeasure.height -
      initialScenePaddingY * 3 -
      initialSceneAnalysisScreenProgressTextContainerMeasure.height;
    let scaleFactor = Math.min(
      maxWidth / stripPhotoSize.width,
      maxHeight / stripPhotoSize.height
    );
    scaleFactor = isFinite(scaleFactor) ? scaleFactor : 0;
    setPhotoSceneScale(scaleFactor);
    const scaledPhotoSceneWidth = stripPhotoSize.width * scaleFactor;
    const left =
      sceneContainerMeasure.left +
      sceneContainerMeasure.width / 2 -
      scaledPhotoSceneWidth / 2;

    return {
      from: {
        top: sceneContainerMeasure.top,
        left,
        scale: scaleFactor,
      },
      to: {
        top: sceneContainerMeasure.top,
        left,
        scale: scaleFactor,
      },
    };
  }, [
    initialScenePhotoContainerMeasure,
    initialSceneAnalysisScreenProgressTextContainerMeasure,
    initialSceneMeasure,
    stripPhotoSize,
    stripAnchorMeasure,
    showPhotoOverlay,
  ]);

  const [photoSceneWrapAnimation, photoSceneWrapSpringApi] = useSpring(() => {
    return {
      from: {
        x: 0,
        y: 0,
        rotate: 0,
        scale: 1,
        transformOrigin: "0px 0px",
      },
      onRest: () => {
        setShowDots(false);
        if (scanResultData) {
          setShowResults(true);
          setAnalysisScreenProgressText(
            AnalysisScreenProgressText.AnalyzingStrip
          );
        }
      },
      config: { duration: overlayAnimationDuration, clamp: true },
    };
  }, [scanResultData]);

  useEffect(() => {
    let transformOrigin = "0px 0px";
    let dotTopLeftBounds: DOMRect | null = null;
    const pointTopLeft = stripViewportPositionTrackingPointRefs.current?.[0];
    const pointTopRight = stripViewportPositionTrackingPointRefs.current?.[1];

    if (pointTopLeft) {
      dotTopLeftBounds = pointTopLeft.getBoundingClientRect();
      transformOrigin = `${dotTopLeftBounds.left}px ${dotTopLeftBounds.top}px`;
    }

    let scale = 1;
    let rotate = 0;
    let x = 0;
    let y = 0;

    if (showPhotoOverlay && dotTopLeftBounds && pointTopRight && stripCorners) {
      const dot1Bounds = dotTopLeftBounds;
      const dot2Bounds = pointTopRight.getBoundingClientRect();
      const stripWidth = vectorDistance(
        pt(dot1Bounds.left, dot1Bounds.top),
        pt(dot2Bounds.left, dot2Bounds.top)
      );
      const scaledDotWidth = dot1Bounds.width * scale;

      scale = stripAnchorMeasure.width / stripWidth;
      y = stripAnchorMeasure.top - dot1Bounds.top - scaledDotWidth;
      x = stripAnchorMeasure.left - dot1Bounds.left - scaledDotWidth;

      const to = {
        rotate,
        x,
        y,
        scale,
        transformOrigin,
      };

      photoSceneWrapSpringApi.stop();
      photoSceneWrapSpringApi.set({ transformOrigin });
      photoSceneWrapSpringApi.start(to);
    }
  }, [
    photoSceneWrapSpringApi,
    showPhotoOverlay,
    stripAnchorMeasure,
    stripCorners,
    stripViewportPositionTrackingPointRefs,
  ]);

  const [dotsAnimations] = useSprings(
    nDots,
    (springIndex: number) => {
      const dotScale = 1 / photoSceneScale;
      const opacity = showDots ? 1 : 0;
      const from = {
        top: stripPhotoSize.height / 2 + "px",
        left: stripPhotoSize.width / 2 + "px",
        scale: dotScale,
        opacity: 0,
      };
      let to = { ...from, opacity };

      if (stripCorners) {
        to = {
          top: stripCorners[springIndex].y + "px",
          left: stripCorners[springIndex].x + "px",
          scale: dotScale,
          opacity,
        };
      }

      return {
        from,
        to,
        delay: showDots && !scanResultData ? 0 : springIndex * 100,
        config: { duration: dotsAnimationDuration, clamp: true },
        onRest: () => {
          if (scanResultData && springIndex === nDots - 1) {
            setShowPhotoOverlay(true);
          }
        },
      };
    },
    [photoSceneScale, scanResultData, showDots, stripCorners]
  );

  const handleResultRowAnimationRest = useCallback(
    (rowIndex: number) => {
      if (
        scanResultData?.predictions &&
        rowIndex === Object.keys(scanResultData.predictions).length - 1
      ) {
        onAnalysisComplete();
        setAnalysisScreenProgressText(AnalysisScreenProgressText.Complete);
      }
    },
    [onAnalysisComplete, scanResultData, setAnalysisScreenProgressText]
  );

  const initResultData = useCallback(
    (testResultData: IScanResult) => {
      if (imageCardDetectorRef.current) {
        const originalCorners =
          imageCardDetectorRef.current.getOriginalStripCornersFromNormalized(
            testResultData.strip_corners
          );

        const transformedCorners =
          imageCardDetectorRef.current.getTransformedStripCorners(
            originalCorners[0],
            1
          );
        const topLeftOriginal = originalCorners[0];
        const topRightOriginal = originalCorners[1];
        const topLeftTransformed = transformedCorners[0];
        const topRightTransformed = transformedCorners[1];
        const vectorDistanceOriginal = vectorDistance(
          pt(topLeftOriginal[0], topLeftOriginal[1]),
          pt(topRightOriginal[0], topRightOriginal[1])
        );
        const vectorDistanceTransformed = vectorDistance(
          pt(topLeftTransformed[0], topLeftTransformed[1]),
          pt(topRightTransformed[0], topRightTransformed[1])
        );
        const scale = vectorDistanceOriginal / vectorDistanceTransformed;
        console.log(vectorDistanceOriginal, vectorDistanceTransformed, scale);

        const scaledTransformedCorners =
          imageCardDetectorRef.current.getTransformedStripCorners(
            originalCorners[0].map((c) => c / scale),
            scale
          );
        const cssTransformMatrix =
          imageCardDetectorRef.current.getCssTransformMatrix(
            originalCorners,
            scaledTransformedCorners
          );
        setPerspxTransformMatrix(cssTransformMatrix);

        const stripCornerPoints = originalCorners.map(([x, y]) => ({ x, y }));
        setStripCorners(stripCornerPoints);
        setAnalysisScreenProgressText(null);
        setScanResultData({
          predictions: testResultData.predictions,
        });
        setShowDots(true);
      }
    },
    [
      imageCardDetectorRef,
      setAnalysisScreenProgressText,
      setScanResultData,
      setShowDots,
      setStripCorners,
    ]
  );

  useEffect(() => {
    if (imageCardDetectorRef.current && imageCanvasRef.current && testResultData) {
      const canvas = imageCanvasRef.current;
      const ctx = canvas.getContext('2d')!;
      const { width, height } = canvas;

      const originalCorners =
        imageCardDetectorRef.current.getOriginalStripCornersFromNormalized(
          testResultData.strip_corners
        );

      imageCardDetectorRef.current.markerCorners = [];

      if (lColorBlockGsapRef.current) {
        lColorBlockGsapRef.current.kill();
        gsap.set(lColorBlockGsapContextRef.current, { alpha: 0 });
      }

      if (rColorBlockGsapRef.current) {
        rColorBlockGsapRef.current.kill();
        gsap.set(rColorBlockGsapContextRef.current, { alpha: 0 });
      }

      if (roiGsapRef.current) {
        roiGsapRef.current.kill();
        gsap.set(roiGsapContextRef.current, { alpha: 0 });
      }

      ctx.beginPath();
      ctx.moveTo(originalCorners[0][0], originalCorners[0][1]);
      originalCorners.slice(1).forEach(([x, y]) => {
        ctx.lineTo(x, y);
      });
      ctx.closePath();

      ctx.rect(0, 0, width, height);
      ctx.clip();
      ctx.globalCompositeOperation = 'source-over';
      ctx.fillStyle = 'rgba(0, 0, 0, 1)';
      ctx.fill('evenodd');

      document.getElementById("analysis-screen")!.style.backgroundColor = "black";
    }
  }, [showPhotoOverlay]);

  useEffect(() => {
    if (testResultData && imageCardDetectorRef.current) {
      const now = Date.now();
      const diff = (now - animatinInittTimestamp) / 1000;
      const delay = 3000 - diff;
      const timeout = Math.max(0, delay);

      delayTimerId = setTimeout(() => {
        try {
          initResultData(testResultData);
        } catch (error) {
          if (onError)
            onError();
          return;
        }
      }, timeout);

      return () => {
        if (delayTimerId) clearTimeout(delayTimerId);
      };
    }
  }, [
    animatinInittTimestamp,
    imageCardDetectorRef,
    initResultData,
    testResultData,
  ]);

  const init = useCallback(
    (stripPhotoFile: File) => {
      if (imageCanvasRef.current) {
        const cardDetector = new ImageCardDetector();
        imageCardDetectorRef.current = cardDetector;
        const image = new Image();
        image.onload = () => {
          const imageCanvas = imageCanvasRef.current!;
          const imageCtx = imageCanvas.getContext("2d")!;

          const maxWidth = 800;
          const maxHeight = 800;

          let width = image.naturalWidth;
          let height = image.naturalHeight;

          if (width > maxWidth || height > maxHeight) {
            const ratio = Math.min(maxWidth / width, maxHeight / height);
            width = width * ratio;
            height = height * ratio;
          }

          setStripPhotoSize({
            width: width,
            height: height,
          });
          // set canvas size
          imageCanvas.width = width;
          imageCanvas.height = height;
          // draw image
          imageCtx.drawImage(image, 0, 0, width, height);
          // get image data
          const imageData = imageCtx.getImageData(0, 0, width, height);

          const isValid = cardDetector.processImage(imageData);

          if (isValid) {
            roiGsapRef.current = gsap.to(roiGsapContextRef.current, {
              alpha: 1,
              delay: 1,
            });
            lColorBlockGsapRef.current = gsap.to(
              lColorBlockGsapContextRef.current,
              {
                duration: 1,
                alpha: 1,
                delay: 1.5,
                repeat: -1,
                yoyo: true,
              }
            );
            rColorBlockGsapRef.current = gsap.to(
              rColorBlockGsapContextRef.current,
              {
                duration: 1,
                alpha: 1,
                delay: 2.5,
                repeat: -1,
                yoyo: true,
              }
            );
          }

          if (animationCanvasRef.current) {
            animationCanvasRef.current.width = width;
            animationCanvasRef.current.height = height;
          }
        }; // Image onload
        image.src = URL.createObjectURL(stripPhotoFile);
      }
    },
    [
      animationCanvasRef,
      imageCanvasRef,
      imageCardDetectorRef,
      lColorBlockGsapContextRef,
      lColorBlockGsapRef,
      rColorBlockGsapContextRef,
      rColorBlockGsapRef,
      roiGsapContextRef,
      roiGsapRef,
    ]
  );

  const renderStripPositionTrackingPoints = useCallback(() => {
    const points = [];
    for (let i = 0; i < 4; i++) {
      const cornerCoords = stripCorners ? stripCorners[i] : { x: 0, y: 0 };
      points.push(
        <div
          key={"point-" + i}
          style={{
            width: 1,
            height: 1,
            top: cornerCoords.y,
            left: cornerCoords.x,
          }}
          ref={(element) => {
            stripViewportPositionTrackingPointRefs.current[i] = element;
          }}
          className="absolute"
        ></div>
      );
    }
    return points;
  }, [stripCorners, stripViewportPositionTrackingPointRefs]);

  useEffect(() => {
    initTimerId = setTimeout(() => {
      if (stripPhoto) {
        init(stripPhoto);
        setAnimationInitTimestamp(Date.now());
      }
    }, 10);
    return () => {
      if (initTimerId) clearTimeout(initTimerId);
    };
  }, [init, stripPhoto]);

  return (
    <div
      id="analysis-screen"
      className="fixed top-0 left-0 w-full h-full overflow-y-auto no-scrollbar"
    >
      <div
        ref={initialSceneRef}
        id="initial-scene"
        className="absolute w-full h-full top-0 left-0"
      >
        <div
          className="max-w-screen-sm mx-auto w-full"
          style={{ padding: `${initialScenePaddingY}px 1.5rem` }}
        >
          <div
            id="initial-scene-photo-container"
            style={{
              height: stripPhotoSize.height
                ? stripPhotoSize.height * photoSceneScale
                : initialSceneMeasure.height -
                  initialSceneAnalysisScreenProgressTextContainerMeasure.height -
                  initialScenePaddingY * 3,
            }}
            ref={initialScenePhotoContainerRef}
          />
          <div
            id="initial-scene-progress-text-container"
            style={{ paddingTop: initialScenePaddingY }}
            ref={initialSceneAnalysisScreenProgressTextContainerRef}
          >
            {progressTextAnimation((style, text, state, index) => {
              return (
                <animated.div style={style}>
                  {text === AnalysisScreenProgressText.QualifyingPhoto && (
                    <div className="flex items-center justify-center font-semibold text-xl gap-3">
                      {text} <Spinner />
                    </div>
                  )}
                </animated.div>
              );
            })}
          </div>
        </div>
      </div>
      <animated.div
        id="photo-scene-wrap"
        className="absolute top-0 left-0 w-full h-full origin-center"
        style={photoSceneWrapAnimation}
      >
        <animated.div
          style={photoSceneAnimation}
          id="photo-scene"
          className="absolute origin-top-left"
        >
          <div
            className="relative"
            ref={perspxTransformWrapRef}
            style={{
              transition: `all ${overlayAnimationDuration}ms`,
              transform: showPhotoOverlay ? perspxTransformMatrix : undefined,
              transformOrigin: "0 0",
            }}
          >
            <canvas ref={imageCanvasRef} className="rounded-3xl"></canvas>
            <AnimationCanvas />
            {dotsAnimations.map((props, index) => {
              return (
                <animated.div
                  key={index}
                  style={props}
                  id={`dot-${index}`}
                  className="z-[500] absolute"
                >
                  <div
                    className="bg-white rounded-full border border-black fixed z-[800] -translate-x-1/2 -translate-y-1/2"
                    style={{ width: dotSize, height: dotSize }}
                  ></div>
                </animated.div>
              );
            })}
            {renderStripPositionTrackingPoints()}
          </div>
        </animated.div>
      </animated.div>
      <ResultsScene onResultRowAnimationRest={handleResultRowAnimationRest} />
    </div>
  );
}
