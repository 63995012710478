import { useAppContext } from "@/contexts/appContext";
import BotImage from "@/assets/img/de1b3c7ec885da63d0fc95324d23fbcd.png";
import useChat from "@/hooks/useChat";
import {
  chatResponseLoadingAtom,
  messagesAtom,
} from "@/store/atoms/messageAtom";
import { showInputBox } from "@/store/atoms/userAtom";
import { useAtom } from "jotai";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import ChatBubbleTyping from "./ChatBubbleTyping";

interface IProps {
  data: {
    predictions: {
      [key: string]: number;
    };
  };
  userId: string;
}

const ChatBubble = ({ data, userId }: IProps) => {
  const [messages] = useAtom(messagesAtom);
  const [chatResponseLoading] = useAtom(chatResponseLoadingAtom);
  const [, setShowInputBox] = useAtom(showInputBox);
  const { session } = useAppContext();

  const { onSend } = useChat(userId, data, session.access_token);

  const handleSend = (text: string) => {
    onSend(text);
  };

  const renderWithBold = (text: string) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) =>
      part.startsWith("**") && part.endsWith("**") ? (
        <strong key={index}>{part.slice(2, -2)}</strong>
      ) : (
        part
      )
    );
  };
  return (
    <div className="flex flex-col space-y-3 h-screen">
      {messages.map((message, index) => (
        <>
          <div
            key={index}
            className={`flex ${
              message.isUser ? "justify-end" : "justify-start"
            } items-start`}
          >
            {!message.isUser && (
              <img
                className="w-8 h-8 rounded-lg mr-2"
                src={BotImage}
                alt="Chat bot image"
              />
            )}
            <div
              className={`flex flex-col w-full max-w-[75%] min-h-[52px] p-4 ${
                message.isUser
                  ? "bg-primary/15 rounded-xl rounded-se-none"
                  : "bg-primary-3 text-gray-900 rounded-e-xl rounded-es-xl dark:bg-gray-700"
              }`}
            >
              <p className="break-words font-normal">
                {message.message.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {renderWithBold(line)}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
          </div>
          {index === 0 && (
            <div className="flex flex-col">
              {["Explain my test results.", "Can I ask you a question?"].map(
                (message, idx) => (
                  <button
                    key={idx}
                    className="ml-9 mb-3 px-4 py-2 max-w-[fit-content] rounded-full border border-primary text-primary hover:bg-primary/10 focus:outline-none whitespace-nowrap overflow-hidden"
                    onClick={() => {
                      handleSend(message);
                      setShowInputBox(true);
                    }}
                  >
                    {message}
                  </button>
                )
              )}
            </div>
          )}
        </>
      ))}

      {chatResponseLoading ? <ChatBubbleTyping /> : null}
    </div>
  );
};

export default ChatBubble;
