import { useAppContext } from "@/contexts/appContext";
import useChat from "@/hooks/useChat";
import { useState } from "react";
import { HiOutlineArrowUp } from "react-icons/hi";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  data: {
    predictions: {
      [key: string]: number;
    };
  };
  userId: string;
}

const ChatFooter = ({ data, userId }: IProps) => {
  const [text, setText] = useState("");
  const { session } = useAppContext();

  const { onSend } = useChat(userId, data, session.access_token);

  const handleSend = () => {
    onSend(text);
    setText("");
  };

  return (
    <div className="flex justify-center items-center mt-4">
      <div className="relative w-[95%]">
        <input
          type="text"
          placeholder="Type here"
          value={text}
          className="w-full p-3 pr-16 pl-5 rounded-full border border-gray-300 text-base outline-none placeholder-slate-400"
          onChange={(event) => {
            setText(event.target.value);
          }}
        />

        <span
          className="absolute top-1/2 right-3 transform -translate-y-1/2 px-2 py-2 bg-primary text-white font-semibold rounded-full transition cursor-pointer"
          onClick={handleSend}
        >
          <HiOutlineArrowUp />
        </span>
      </div>
    </div>
  );
};

export default ChatFooter;
