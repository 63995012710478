import * as Sentry from '@sentry/react';
import posthog from "posthog-js";
import { Session } from "@supabase/supabase-js";

class LogService {
  private static instance: LogService;

  constructor() {
    if (!LogService.instance) {
      LogService.instance = this;
    }

    if (!posthog.__loaded) {
      posthog.init(process.env.REACT_APP_POSTHOG_KEY!);
    }

    if (!Sentry.isInitialized()) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
          posthog.sentryIntegration({
            organization: process.env.REACT_APP_SENTRY_ORG_URL,
            projectId: Number(process.env.REACT_APP_SENTRY_PROJECT_ID),
            severityAllowList: ['error', 'info']
          }),
          Sentry.browserTracingIntegration(),
        ],
      });
    }

    return LogService.instance;
  }

  public static getInstance(): LogService {
    if (!LogService.instance) {
      LogService.instance = new LogService();
    }
    return LogService.instance;
  }

  public setUser(session: Session) {
    posthog.identify(
      session.user.id,
      {
        email: session.user.user_metadata.email,
        phone: session.user.phone
      }
    );

    Sentry.setUser({
      id: session.user.id,
      email: session.user.user_metadata.email,
      username: session.user.phone
    });
  }

  public captureException(error: Error, context?: Record<string, any>) {
    Sentry.captureException(error, {
      contexts: context ? { additional: context } : undefined,
    });
  }

  public captureMessage(message: string, level?: Sentry.SeverityLevel) {
    Sentry.captureMessage(message, level || 'error');
  }
}

export const logService = LogService.getInstance();
